@import "../_sass/settings";
        * {
    -webkit-transition: none !important;
    transition: none !important;
    }
    *,
    *:before,
    *:after {
        background: transparent !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    *{transition:none!important}
    @page {
        margin: 0.75in 0.5in 0.75in 0.5in;
        orphans:4; widows:2;
    }

    body {
        font-size:  12pt;

    }
    html body span.print-footer{
      font-family: $sans-font;
      font-size: 9pt;
      margin-top: 22.4pt;
      padding-top: 4pt;
      border-top: 1px solid #000;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 4;
        widows: 4;
    }
    article h2, article h2 h3, article h3, article h3 h4, article h4, article h4 h5 {
        page-break-after: avoid;
    }

    body header , body footer {
      display:none;
    }
