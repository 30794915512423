/* This file contains all the constants for colors and font styles */

$body-font: 'Lato', "Lucida Grande", Tahoma, sans-serif;
// Note that Gill Sans is the top of the stack and corresponds to what is used in Tufte's books
// However, it is not a free font, so if it is not present on the computer that is viewing the webpage
// The free Google 'Lato' font is used instead. It is similar.
$sans-font: 'Nunito', Helvetica, Arial, sans-serif;
$code-font: Consolas, "Liberation Mono", Menlo, Courier, monospace;
$url-font: "Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace;
$text-color: #47475f;
$bg-color: #fafaff;
$contrast-color: #fd0091;
$border-color: #333333;
$link-style: color; // choices are 'color' or 'underline'. Color option styles links with $contrast-color set above

.next {
    float: right;
}

.previous-next {
    margin-top: 30px;
    width: 55%;
}

footer>div.categories a {
    padding-top: 0.5rem !important;
    float: none !important;
}

footer>div.categories {
    text-align: center;
    margin: 0 auto 15px;
}

.pagination {
    width: 320px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2rem;
}

.page_number {
    margin: 0 30px;
}

article .categories {
    display: inline;
}

pre,
pre code,
p code,
p pre code {
    background: #FFFFFF;
}

//
// Staticman new comment form
//
#respond {
    max-width: 500px;
}

input,
select,
textarea,
label,
button {
    //    @extend body;
    //  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $bg-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}

fieldset {
    border: none;
    padding: 0;
}

// each staticman text field is wrapped in this div
.textfield {
    max-width: 100%;
    padding: 0.5rem 0;
}

// fields except the comment field get this class too
// to keep them a reasonable size
.narrowfield {
    width: 420px;
}

.hp {
    display: none;
}

textarea {
    vertical-align: top;
}

.textfield__input {
    border: 1px solid rgba(0, 0, 0, .12);
    padding: 4px 5px;
    width: 100%;
    font-size: 14px;
    background-color: $bg-color;
}

.notify-me,
.g-recaptcha {
    padding: 0.5rem 0;
}

.button {
    background: rgba(158, 158, 158, .2);
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, .14);
    border: none;
    border-radius: 2px;
    padding: 4px 8px;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin: 4px 0px 0px 0px;
}

button:hover {
    background-color: $border-color;
    color: white;
}

//
// Staticman existing comments
//
.comments {}

.comment__new {
    padding: 0px 0px 14px 0px;
}

.comment__author {
    color: $text-color;
}

.comment__date {
    font-size: 14px;
}

.comment__body {
    font-size: 14px;
}

.comment__meta {
    text-align: left;
    color: $border-color;
    font-size: 14px;
}

.comments__existing {
    padding: 8px 0px 0px 0px;
}

.comment {
    border-top: 0px solid $border-color;
    padding: 0px 0px 0px 0px;
    margin-bottom: 1em;
}

.comment.child {
    margin-left: 3em;
}

.comment__reply-link {
    color: $bg-color;
    font-size: 14px;
    cursor: pointer;
}

//
// Pop-up modal window for Staticman comments
//
.show-modal {
    overflow: hidden;
    position: relative;
}

.show-modal:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.85);
}

.show-modal .modal {
    display: block;
}

.mdl-card {
    min-height: 200px;
    overflow: hidden;
    z-index: 1;
    position: relative;
    background: #fff;
    box-sizing: border-box;
}

.modal {
    display: none;
    position: fixed;
    width: 330px;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -150px;
    min-height: 0;
    z-index: 9999;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, .14);
    padding: 16px;
    border-radius: 2px;
    background: white;
}

.mdl-shadow--16dp {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
}

.mdl-card__supporting-text {
    overflow: hidden;
    padding: 16px;
    width: 90%;
}

.modal-title {
    margin-bottom: 0px;
}

.disabled {

    input,
    button,
    textarea,
    label {
        pointer-events: none;
        cursor: not-allowed;
        filter: alpha(opacity=65);
        box-shadow: none;
        opacity: 0.65;
    }
}